export enum AppTheme {
  DEFAULT = 'default',
  AGKAMED = 'agkamed',
  AGKAMED_TEST = 'agkamed-test',
  AKK = 'akk',
  AMEOS = 'ameos',
  EKK = 'ekk',
  EKK_TEST = 'ekk-test',
  JDS = 'jds',
  MEDIIO = 'mediio',
  SANA = 'sana',
  DIAKOVERE = 'diakovere',
}
